<template>
  <div>
    <h2 class="text-xl font-semibold mb-4 text-white">Engagement Strategies <span class="text-sm text-gray-500"><span
          class="text-sm text-gray-500">(optional)</span></span></h2>
    <div class="space-y-4">

      <div>
        <label for="feedback" class="block text-sm font-medium text-gray-300">Feedback and reinforcement: <span
            class="text-xs">Instant feedback, hints, explanations, etc.</span></label>
        <textarea id="feedback" v-model="localData.feedback"
          class="mt-1 p-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white placeholder-gray-400 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
          rows="4"
          placeholder="Provide instant feedback during scenario interactions, highlighting correct actions and explaining errors. Use hints to guide learners through challenging decisions without giving away answers. Offer detailed explanations after each scenario to reinforce learning, emphasizing key compliance points and best practices. Encourage reflection on how feedback applies to real-world situations."></textarea>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps(['modelValue']);
const emit = defineEmits(['update:modelValue']);

const localData = ref(props.modelValue || { interactivity: '', gamification: '', feedback: '' });

watch(localData, (newValue) => {
  emit('update:modelValue', newValue);
}, { deep: true });
</script>