<template>
    <div class="file-uploader">
        <p class="text-sm font-medium text-gray-300 pb-1">Add files the AI should refer to durning the role play
            (optional)
        </p>

        <input type="file" id="file-input" @change="handleFileChange" multiple
            accept=".txt,.pdf,.md,.html,.csv,.json,.ppt, pptx,.doc, .docx " />
        <label for="file-input"
            class="px-2 py-1 bg-gray-600 text-white rounded hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50">Choose
            Files</label>
        <!-- list the files selected -->
        <div v-if="files.length > 0" class="mt-2">
            <p class="text-sm font-medium text-gray-300">Selected Files:</p>
            <ul class="list-disc list-inside">
                <li v-for="file in files" :key="file.name" class="text-sm text-gray-300">{{ file.name }}</li>
            </ul>
        </div>

    </div>
</template>
<style scoped>
.file-uploader {
    position: relative;
    display: inline-block;
}

#file-input {
    display: none;
}
</style>

<script setup>
import { ref } from 'vue';
import axios from 'axios';

const props = defineProps(['modelValue']);
const emit = defineEmits(['filesAdded']);

const files = ref([]);


const handleFileChange = (event) => {
    files.value = Array.from(event.target.files);
    uploadFiles();
};

const uploadFiles = async () => {
    const formData = new FormData();
    files.value.forEach((file) => formData.append('files', file));

    try {
        const response = await axios.post('/api/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        emit('filesAdded', response.data.vector_store_id);
    } catch (error) {
        console.error('Error uploading files:', error);
    }
};
</script>
