<template>
  <div
    class="min-h-screen text-white  flex flex-col justify-center py-12 bg-gradient-to-br from-gray-900 via-blue-900 to-purple-900">
    <!-- Loading Overlay -->
    <div v-if="pending" class="absolute inset-0 bg-gray-900/50 backdrop-blur-sm flex items-center justify-center z-50">
      <div class="text-white text-center items-center content-center">
        <p class="text-xl font-semibold">Loading data...</p>
        <LoaderCircle class="h-8 w-8 animate-spin mx-auto" />
      </div>
    </div>
    <div class="relative py-3 w-full md:w-3/4 mx-auto">
      <div
        class="absolute inset-0 bg-gradient-to-r from-blue-400 to-light-blue-500 shadow-lg transform -skew-y-6 skew-y-0 -rotate-6 rounded-3xl">
      </div>
      <div class="relative px-4 py-6 md:p-10 bg-gray-800 shadow-lg rounded-3xl ">
        <div class="mx-auto">
          <h1 class="text-2xl font-semibold mb-4 text-white">Create Your Scenario</h1>
          <!-- Timeline Navigation -->
          <div v-if="showForm" class="mb-8 relative">
            <div class="absolute top-1/2 left-0 right-0 h-0.5 bg-gray-600 transform -translate-y-1/2"></div>
            <div class="relative flex justify-between">
              <button v-for="(step, index) in steps" :key="index" @click="goToStep(index)" :class="[
                'w-6 h-6 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white',
                index <= currentStepIndex ? 'bg-blue-500' : 'bg-gray-600',
                { 'ring-2 ring-offset-2 ring-offset-gray-800 ring-white': index === currentStepIndex }
              ]" :aria-label="`Go to step ${index + 1}`">
                <span class="sr-only">{{ step.name }}</span>
              </button>
            </div>
          </div>
          <div class="fixed-height-container p-2 overflow-y-auto">
            <CreatorWelcome v-if="!showForm" @update:showForm="showForm = true" />
            <transition v-else name="fade" mode="out-in">
              <component v-if="currentStepIndex < steps.length - 1 && currentStepIndex > -1" :is="currentStep"
                :key="currentStepIndex" :modelValue="scenarioData[steps[currentStepIndex].name]"
                @update:modelValue="$event => (scenarioData[steps[currentStepIndex].name] = $event)">
              </component>
              <Summary v-else class="" :modelValue="scenarioData" @update:currentStepIndex="goToStep($event)">
              </Summary>
            </transition>
          </div>
          <div v-if="showForm" class="mt-6 flex justify-between">
            <div v-if="currentStepIndex > 0" class="flex-1">
              <button @click="prevStep"
                class="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50">
                Previous
              </button>
            </div>
            <div v-if="currentStepIndex < steps.length - 1" class="flex-1 text-right">
              <button @click="nextStep"
                class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50">
                Next
              </button>
            </div>
            <div v-if="currentStepIndex === steps.length - 1" class="flex-1 text-right">
              <button @click="submitScenario" v-if="!assistantId"
                class="px-4 py-2 bg-green-500 text-white rounded enabled:hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-50 disabled:opacity-75"
                :disabled="scenarioData['Scenario Overview'].overview.length < 100 || scenarioData['Scenario Overview'].title.length < 5">
                Submit Scenario
              </button>
              <button @click="updateScenario" v-else
                class="px-4 py-2 bg-green-500 text-white rounded enabled:hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-50 disabled:opacity-75"
                :disabled="scenarioData['Scenario Overview'].overview.length < 100 || scenarioData['Scenario Overview'].title.length < 5">
                Update Scenario
              </button>
            </div>
          </div>
          <div v-if="currentStepIndex === steps.length - 1" class="mt-6 text-center">
            <p v-if="scenarioData['Scenario Overview'].overview.length < 100" class="text-sm text-gray-100">
              Your scenario overview must be at least 100 characters long.</p>
            <p v-if="scenarioData['Scenario Overview'].title.length < 5" class="text-sm text-gray-100">
              Your scenario overview must be at least 5 characters long.</p>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import { LoaderCircle } from 'lucide-vue-next'
import { useRoute } from 'vue-router'
import CreatorWelcome from './CreatorWelcome.vue';
import ScenarioOverview from './steps/ScenarioOverview.vue';
import LearningObjectives from './steps/LearningObjectives.vue';
import TargetAudience from './steps/TargetAudience.vue';
import ContentDetails from './steps/ContentDetails.vue';
import EngagementStrategies from './steps/EngagementStrategies.vue';
import AssessmentMethods from './steps/AssessmentMethods.vue';
import MotivationalFactors from './steps/MotivationalFactors.vue';
import ScenarioSetting from './steps/ScenarioSetting.vue';
import Constraints from './steps/Constraints.vue';
import Summary from './steps/Summary.vue';

const route = useRoute();

const steps = [
  { component: ScenarioOverview, name: 'Scenario Overview' },
  { component: ScenarioSetting, name: 'Scenario Setting' },
  { component: LearningObjectives, name: 'Learning Objectives' },
  { component: TargetAudience, name: 'Target Audience' },
  { component: ContentDetails, name: 'Content Details' },
  { component: EngagementStrategies, name: 'Engagement Strategies' },
  { component: AssessmentMethods, name: 'Assessment Methods' },
  { component: MotivationalFactors, name: 'Motivational Factors' },
  { component: Constraints, name: 'Constraints' },
  { component: Summary, name: 'Summary' }
];

const currentStepIndex = ref(0);
const showForm = ref(false);
const currentStep = computed(() => steps[currentStepIndex.value].component);
const assistantId = ref(null);
const pending = ref(false);

const scenarioData = ref({
  'Scenario Overview': { overview: '', title: '' },
  'Scenario Setting': { theme: 'Realistic', timeLength: 'Short (1-5 minutes)' },
  'Learning Objectives': {},
  'Target Audience': {},
  'Content Details': {},
  'Engagement Strategies': {},
  'Assessment Methods': {},
  'Motivational Factors': {},
  'Constraints': {}
});

const nextStep = () => {
  if (currentStepIndex.value < steps.length - 1) {
    currentStepIndex.value++;
  }
};

const prevStep = () => {
  if (currentStepIndex.value > 0) {
    currentStepIndex.value--;
  }
};

const goToStep = (index) => {
  currentStepIndex.value = index;
};

const submitScenario = async () => {
  pending.value = true;
  // send the data to your backend
  await axios.post('/api/assistant', { instructions: scenarioData.value, title: scenarioData.value['Scenario Overview'].title });
  pending.value = false;
  //navigate to the home page
  window.location.href = '/';
};

const updateScenario = async () => {
  pending.value = true;
  // send the data to your backend
  await axios.put('/api/assistant/' + assistantId.value, { instructions: scenarioData.value, title: scenarioData.value['Scenario Overview'].title });
  pending.value = false;
  window.location.href = '/';
};

onMounted(() => {
  //if the route is '/edit/:id' fetch the scenario data
  if (route.name === 'editor') {
    const publicId = route.params.id;
    axios.get('/api/assistants/' + publicId).then(response => {
      const jsonData = response.data.data[0].json_data;

      const orderedData = {
        'Scenario Overview': jsonData['Scenario Overview'] || { overview: '', title: '' },
        'Scenario Setting': jsonData['Scenario Setting'] || { theme: 'Realistic', timeLength: 'Short (1-5 minutes)' },
        'Learning Objectives': jsonData['Learning Objectives'] || {},
        'Target Audience': jsonData['Target Audience'] || {},
        'Content Details': jsonData['Content Details'] || {},
        'Engagement Strategies': jsonData['Engagement Strategies'] || {},
        'Assessment Methods': jsonData['Assessment Methods'] || {},
        'Motivational Factors': jsonData['Motivational Factors'] || {},
        'Constraints': jsonData['Constraints'] || {}
      };

      scenarioData.value = orderedData;
      assistantId.value = response.data.data[0].assist_id;
      showForm.value = true;
    });
  }

});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fixed-height-container {
  height: 450px;
  /* Set a fixed height */

}
</style>