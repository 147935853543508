<template>
  <div>
    <h2 class="text-xl font-semibold mb-4 text-white">Assessment Methods <span class="text-sm text-gray-500"><span
          class="text-sm text-gray-500">(optional)</span></span></h2>
    <div class="space-y-4">
      <div>
        <label for="keyWordsOrPhrases" class="block text-sm font-medium text-gray-300">What key words or phrases should
          the learner <span class="italic font-bold">include</span>:</label>
        <textarea id="keyWordsOrPhrases" v-model="localData.LearnerShouldUseKeyWordsOrPhrases"
          class="mt-1 p-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white placeholder-gray-400 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
          rows="3"
          placeholder="Include terms such as 'Protected Health Information (PHI)', 'Minimum Necessary Rule', 'patient consent', 'confidentiality', 'privacy violation', and 'authorized access'. Use phrases like 'complying with HIPAA', 'handling sensitive information', and 'ensuring patient privacy'. These keywords reinforce the compliance focus and help connect scenario actions to core principles."></textarea>
      </div>
      <div>
        <label for="avoidWordsOrPhrases" class="block text-sm font-medium text-gray-300">What key words or phrases
          should
          the learner <span class="italic font-bold">avoid</span>:</label>
        <textarea id="avoidWordsOrPhrases" v-model="localData.LearnerShouldAvoidWordsOrPhrases"
          class="mt-1 p-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white placeholder-gray-400 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
          rows="3"
          placeholder="Avoid using terms like 'share freely', 'assume consent', 'bypass privacy rules', and 'no need for authorization'. Refrain from phrases such as 'not important', 'ignore privacy policies', or 'confidentiality isn’t necessary'. These terms undermine the importance of compliance and contradict the principles of HIPAA regulations."></textarea>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps(['modelValue']);
const emit = defineEmits(['update:modelValue']);

const localData = ref(props.modelValue || { keyWordsOrPhrases: '', avoidWordsOrPhrases: '' });

watch(localData, (newValue) => {
  emit('update:modelValue', newValue);
}, { deep: true });
</script>