<template>
  <div>
    <h2 class="text-xl font-semibold mb-4 text-white">Target Audience <span
        class="text-sm text-gray-500">(optional)</span></h2>
    <div class="space-y-4">
      <div>
        <label for="demographics" class="block text-sm font-medium text-gray-300">Learner demographics: <span
            class="text-xs">Age, background, job role, etc.</span></label>
        <textarea id="demographics" v-model="localData.demographics"
          class="mt-1 p-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white placeholder-gray-400 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
          rows="3"
          placeholder="Healthcare professionals including nurses, medical assistants, and administrative staff. Ages 25-55, with varying levels of experience in patient care. Primarily working in clinics, hospitals, or private practices. Basic knowledge of HIPAA regulations but needing more practical application skills."></textarea>
      </div>
      <div>
        <label for="priorKnowledge" class="block text-sm font-medium text-gray-300">Prior knowledge or skill
          level:</label>
        <textarea id="priorKnowledge" v-model="localData.priorKnowledge"
          class="mt-1 p-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white placeholder-gray-400 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
          rows="3"
          placeholder="Basic understanding of patient privacy and HIPAA regulations. Familiarity with general healthcare protocols but limited experience in handling specific compliance scenarios. Moderate communication skills, with some exposure to privacy policy discussions but needing further refinement in practical application."></textarea>
      </div>

    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps(['modelValue']);
const emit = defineEmits(['update:modelValue']);

const localData = ref(props.modelValue || { demographics: '', priorKnowledge: '', accessibility: '' });

watch(localData, (newValue) => {
  emit('update:modelValue', newValue);
}, { deep: true });
</script>