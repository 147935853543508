<template>
  <div>
    <h2 class="text-xl font-semibold mb-4 text-white">Content Details <span class="text-sm text-gray-500"><span
          class="text-sm text-gray-500">(optional)</span></span></h2>
    <div class="space-y-4">
      <div>
        <label for="coreTopics" class="block text-sm font-medium text-gray-300">Core topics, theories, or
          skills:</label>
        <textarea id="coreTopics" v-model="localData.coreTopics"
          class="mt-1 p-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white placeholder-gray-400 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
          rows="3"
          placeholder="Core Topics: HIPAA Privacy Rule, Minimum Necessary Rule, handling patient consent, and safeguarding Protected Health Information (PHI). Theories: Privacy and confidentiality principles in healthcare. Skills: Identifying sensitive information, effective communication with patients regarding privacy rights, and responding appropriately to unauthorized access requests."></textarea>
      </div>
      <div>
        <label for="examples" class="block text-sm font-medium text-gray-300">Relevant case studies or real-world
          examples:</label>
        <textarea id="examples" v-model="localData.examples"
          class="mt-1 p-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white placeholder-gray-400 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
          rows="3"
          placeholder="Case Study: A healthcare worker accidentally discloses patient information to an unauthorized family member. Real-World Example: A hospital faces penalties for failing to secure patient records. Scenario: Staff responding to a request for information without proper verification of consent. Use these examples to illustrate consequences of non-compliance and best practices for protecting patient privacy."></textarea>
      </div>

    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps(['modelValue']);
const emit = defineEmits(['update:modelValue']);

const localData = ref(props.modelValue || { coreTopics: '', examples: '', materials: '' });

watch(localData, (newValue) => {
  emit('update:modelValue', newValue);
}, { deep: true });
</script>