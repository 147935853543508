<template>
  <div>
    <h2 class="text-xl font-semibold mb-4 text-white">Motivational Factors <span
        class="text-sm text-gray-500">(optional)</span></h2>
    <div class="space-y-4">
      <div>
        <label for="motivationalDrivers" class="block text-sm font-medium text-gray-300">Motivational drivers: <span
            class="text-xs">Rewards, real-world relevance, etc.</span></label>
        <textarea id="motivationalDrivers" v-model="localData.motivationalDrivers"
          class="mt-1 p-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white placeholder-gray-400 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
          rows="3"
          placeholder="Highlight the real-world relevance of protecting patient privacy to build trust and maintain professional integrity. Emphasize the legal and ethical responsibilities to motivate compliance. Use rewards such as certificates of completion, recognition within the workplace, or continued professional development credits. Showcase the positive impact on patient relationships and the avoidance of legal penalties as motivators."></textarea>
      </div>
      <div>
        <label for="relevance" class="block text-sm font-medium text-gray-300">How content relates to learners' personal
          or professional life:</label>
        <textarea id="relevance" v-model="localData.relevance"
          class="mt-1 p-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white placeholder-gray-400 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
          rows="3"
          placeholder="Demonstrate how compliance with HIPAA directly impacts their daily work routines and professional reputation. Relate scenarios to common situations they encounter, such as handling patient information at the front desk or during medical consultations. Highlight the personal responsibility to protect patient data, reinforcing the connection between their actions and the trust placed in them by patients and employers. Emphasize the importance of upholding ethical standards in their professional roles to enhance career growth and job security."></textarea>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps(['modelValue']);
const emit = defineEmits(['update:modelValue']);

const localData = ref(props.modelValue || { motivationalDrivers: '', relevance: '' });

watch(localData, (newValue) => {
  emit('update:modelValue', newValue);
}, { deep: true });
</script>