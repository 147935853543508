<!-- frontend/src/App.vue -->
<template>
  <div>

    <router-view />
  </div>
</template>
<script setup>

</script>
<style></style>