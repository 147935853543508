<template>
  <div>
    <h2 class="text-xl font-semibold mb-4 text-white">Learning Objectives <span class="text-sm text-gray-500"><span
          class="text-sm text-gray-500">(optional)</span></span></h2>
    <div v-if="localData" class="space-y-4">
      <div>
        <label for="skills" class="block text-sm font-medium text-gray-300">Specific skills or knowledge to
          acquire:</label>
        <textarea id="skills" v-model="localData.skills"
          class="mt-1 p-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white placeholder-gray-400 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
          rows="3"
          placeholder="Ability to identify situations requiring patient consent before sharing information. Skill in communicating privacy policies effectively to patients and their families."></textarea>
      </div>
      <div>
        <label for=" outcomes" class="block text-sm font-medium text-gray-300">Desired outcomes:</label>
        <textarea id="outcomes" v-model="localData.outcomes"
          class="mt-1 p-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white placeholder-gray-400 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
          rows="3"
          placeholder="Ensure all staff members comply with HIPAA regulations when handling patient information. Improve communication skills in explaining privacy policies to patients and families."></textarea>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';

const props = defineProps(['modelValue']);
const emit = defineEmits(['update:modelValue']);

const localData = ref(props.modelValue);
console.log("local", localData.value);

onMounted(() => {
  console.log("local", localData.value);
  console.log("props", props.modelValue);
  if (!localData.value) {
    localData.value = { skills: '', outcomes: '' };
  }
});

watch(localData, (newValue) => {
  emit('update:modelValue', newValue);
}, { deep: true });
</script>