<template>
    <div class="max-w-md mx-auto">
        <form @submit.prevent="subscribe">
            <div class="mb-4">
                <label for="userEmail" class="block text-sm font-medium text-gray-700">Email</label>
                <input type="userEmail" id="userEmail" v-model="userEmail" required
                    class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
            </div>

            <div class="mb-4">
                <label for="userName" class="block text-sm font-medium text-gray-700">Name</label>
                <input type="text" id="userName" v-model="userName"
                    class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
            </div>

            <button type="submit"
                class="w-full bg-indigo-600 text-white py-2 px-4 rounded hover:bg-indigo-700 focus:outline-none disabled:bg-gray-300"
                :disabled="!userEmail || !userName">
                Get updates
            </button>
        </form>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';

const emit = defineEmits(['closeModal']);
const userEmail = ref(null);
const userName = ref(null);

const subscribe = async () => {
    await axios.post("/api/signup", { name: userName.value, email: userEmail.value })
    emit('closeModal')
};
</script>

<style scoped>
/* Optional: Add custom styles here */
</style>
