<template>
  <div class="">
    <h2 class="text-xl font-semibold mb-4 text-white">Summary</h2>
    <div class="space-y-4">
      <div v-for="(section, key, index) in localData" :key="key" class="bg-gray-700 p-4 rounded-md cursor-pointer"
        @click="jumpToStep(index)">
        <h3 class="text-lg font-medium text-white mb-2">{{ formatTitle(key) }}</h3>
        <div v-for="(value, subKey) in section" :key="subKey" class="ml-4">
          <p class="text-sm font-medium text-gray-300">{{ formatTitle(subKey) }}:</p>
          <p class="text-sm text-white">{{ formatValue(value) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue';

const props = defineProps(['modelValue']);
const localData = ref(props.modelValue);
const emit = defineEmits(['update:currentStepIndex']);

const jumpToStep = (index) => {
  console.log('Jumping to step', index);
  emit('update:currentStepIndex', index);
};

const formatTitle = (str) => {
  return str.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
};

const formatValue = (value) => {
  if (Array.isArray(value)) {
    return value.join(', ');
  }
  return value;
};
</script>