// frontend/src/router.js
import { createRouter, createWebHistory } from "vue-router";
import ScenarioCreator from "./components/ScenarioCreator.vue";
import Chat from "./components/Chat.vue";
import ScenarioList from "./components/ScenarioList.vue";

const routes = [
  { path: "/", component: ScenarioList, name: "scenarios" },
  { path: "/create", component: ScenarioCreator, name: "creator" },
  { path: "/chat/:id", component: Chat, name: "chat" },
  { path: "/edit/:id", component: ScenarioCreator, name: "editor" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
