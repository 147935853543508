<template>
  <div>
    <h2 class="text-xl font-semibold mb-4 text-white">Constraints and Limitations <span
        class="text-sm text-gray-500"><span class="text-sm text-gray-500">(optional)</span></span></h2>
    <div class="space-y-4">
      <div>
        <label for="budgetTimeResources" class="block text-sm font-medium text-gray-300">Budget, time, or resource
          limitations:</label>
        <textarea id="budgetTimeResources" v-model="localData.budgetTimeResources"
          class="mt-1 p-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white placeholder-gray-400 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
          rows="3"
          placeholder="High patient load limits time for compliance checks. Budget constraints restrict access to tools that streamline privacy tasks. Limited resources for quick access to consent forms, often requiring manual verification. Must juggle patient care with privacy protocols under tight time constraints."></textarea>
      </div>
      <div>
        <label for="regulatoryCompliance" class="block text-sm font-medium text-gray-300">Regulatory or compliance
          requirements:</label>
        <textarea id="regulatoryCompliance" v-model="localData.regulatoryCompliance"
          class="mt-1 p-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white placeholder-gray-400 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
          rows="3"
          placeholder="When disclosing or using PHI, only the minimum amount of information necessary to accomplish the intended purpose should be shared."></textarea>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps(['modelValue']);
const emit = defineEmits(['update:modelValue']);

const localData = ref(props.modelValue || { budgetTimeResources: '', regulatoryCompliance: '' });

watch(localData, (newValue) => {
  emit('update:modelValue', newValue);
}, { deep: true });
</script>