<template>
    <div>
        <h2 class="text-xl font-semibold mb-4 text-white">Scenario Overview (required)</h2>
        <div v-if="localData" class="space-y-4">
            <div>
                <label for="title" class="block text-sm font-medium text-gray-300">Title: <span class="text-xs">A
                        descriptive title for the scenario</span></label>
                <input id="title" v-model="localData.title"
                    class="mt-1 p-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white placeholder-gray-400 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                    type="text" placeholder="HIPAA Compliance in a Medical Office" />
            </div>
            <div>
                <label for="overview" class="block text-sm font-medium text-gray-300">Overview: <span class="text-xs">A
                        general description of the scenario. Min length 100</span></label>
                <textarea id="overview" v-model="localData.overview"
                    class="mt-1 p-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white placeholder-gray-400 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                    rows="7"
                    placeholder="A nurse in a medical office receives a call from a patient's family member requesting details about the patient’s recent test results. According to HIPAA regulations, the nurse cannot disclose any health information without the patient’s explicit consent. The nurse politely informs the caller that the patient must authorize the release of information and directs them to the correct process to obtain the necessary consent. This ensures the patient’s privacy is protected in compliance with HIPAA standards."></textarea>
                <!--characoter counter -->
                <div class="flex justify-end text-xs text-gray-500 space-x-1">
                    <span>{{ localData.overview.length }} </span> <span> (Min 100)</span>
                </div>
            </div>
            <FileUploader v-model="localData.vectorStore" @filesAdded="handlefilesAdded" />
        </div>
    </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import FileUploader from '../FileUploader.vue';

const props = defineProps(['modelValue']);
const emit = defineEmits(['update:modelValue']);

const localData = ref(props.modelValue);

onMounted(() => {
    if (!localData.value) {
        localData.value = props.modelValue;
    }
});

watch(localData, (newValue) => {
    emit('update:modelValue', newValue);
}, { deep: true });

const handlefilesAdded = (vector_store_id) => {
    localData.value.vector_store_id = vector_store_id;
};
</script>