<template>
  <div>
    <h2 class="text-xl font-semibold mb-4 text-white">Scenario Settings <span class="text-sm text-gray-500"><span
          class="text-sm text-gray-500">(optional)</span></span></h2>
    <div v-if="localData" class="space-y-4">
      <div>
        <label for="theme" class="block text-sm font-medium text-gray-300">Scenario theme:</label>
        <select id="theme" v-model="localData.theme"
          class="mt-1 p-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50">
          <option value="Realistic">Realistic</option>
          <option value="Fictional">Fictional</option>
          <option value="Problem-solving">Problem-solving</option>
        </select>
      </div>
      <div>
        <label for="characters" class="block text-sm font-medium text-gray-300">Characters: <span
            class="text-xs">Describe characters that resonate with the learners</span></label>
        <textarea id="characters" v-model="localData.characters"
          class="mt-1 p-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white placeholder-gray-400 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
          rows="5" placeholder="Nurse Emma: A dedicated nurse, compassionate and professional.
          Patient John: Middle-aged, values his privacy. 
          Family Member Sarah: Concerned sister, caring but sometimes oversteps boundaries."></textarea>
      </div>
      <div>
        <!-- Add a new field for setting targeted the scenario time length-->
        <label for="timeLength" class="block text-sm font-medium text-gray-300">Scenario time length:</label>
        <select id="timeLength" v-model="localData.timeLength"
          class="mt-1 p-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50">
          <option value="Short (1-5 minutes)">Short (1-5 minutes)</option>
          <option value="Medium (5-10 minutes)">Medium (5-10 minutes)</option>
          <option value="Long (10-20 minutes)">Long (10-20 minutes)</option>
        </select>

      </div>

    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';

const props = defineProps(['modelValue']);
const emit = defineEmits(['update:modelValue']);

const localData = ref(props.modelValue);

onMounted(() => {
  if (!localData.value) {
    localData.value = props.modelValue;
  }
});

watch(localData, (newValue) => {
  emit('update:modelValue', newValue);
}, { deep: true });
</script>