<template>
    <div>
        <h2 class="text-xl font-semibold mb-4 text-white">Let's build a custom scenario!</h2>
        <p class="text-white">Welcome to the scenario creator! Here you can build a custom scenario for your learners.
            The only required field is the Overview. But the more detail you provide the better the results.</p>
        <div class="mt-6">
            <button @click="startCreation"
                class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50">
                Start Scenario Creation
            </button>

        </div>

    </div>

</template>

<script setup>
import { ref } from 'vue';
const emit = defineEmits(['update:currentStepIndex']);


const startCreation = () => {
    console.log('Starting scenario creation');
    emit('update:showForm');
}


</script>